<template>
    <article class="position-relative">
        <emsculpt-home></emsculpt-home>
        <emsculpt-features></emsculpt-features>
        <emsculpt-technology></emsculpt-technology>
        <emsculpt-feedback></emsculpt-feedback>
        <emsculpt-comparison></emsculpt-comparison>
        <enquiry duplicate-to-g-sheet
                 with-extra-booking-discounts
                 g-sheet-id-key="branch"
                 v-bind:g-sheet-id="gSheetIds"
                 v-bind:extra-booking-discounts="extraBookingDiscounts"
                 v-bind:treatment-id="treatmentId">
            <span class="font-noto-serif-tc">
                立即預約首次體驗或免費體態咨詢
            </span>
        </enquiry>
        <btn-register-now></btn-register-now>
    </article>
</template>

<script>
    import Enquiry from "./Enquiry";
    import EmsculptComparison from "./Emsculpt/Comparison";
    import EmsculptHome from "./Emsculpt/Home";
    import EmsculptTechnology from "./Emsculpt/Technology";
    import EmsculptFeatures from "./Emsculpt/Features";
    import EmsculptFeedback from "./Emsculpt/Feedback";
    import BtnRegisterNow from "./Emsculpt/BtnRegisterNow";
    export default {
        name: "Emsculpt",
        components: {
            BtnRegisterNow,
            EmsculptFeedback,
            EmsculptFeatures,
            EmsculptTechnology,
            EmsculptHome,
            EmsculptComparison,
            Enquiry
        },
        metaInfo () {
            return {
                title: this.$sanitize(this.treatment.title[this.$i18n.locale]),
                meta: [{
                    "name": "description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "description",
                }, {
                    "property": "og:title",
                    "content": this.$sanitize(this.treatment.title[this.$i18n.locale]),
                    "template": "%s | marier Skincare 曼肌兒",
                    "vmid": "og:title"
                }, {
                    "property": "og:description",
                    "content": this.$sanitize(this.treatment.subtitle[this.$i18n.locale]),
                    "vmid": "og:description"
                }, {
                    "property": "og:type",
                    "content": "profile",
                    "vmid": "og:type"
                }, {
                    "property": "fb:app_id",
                    "content": "148635335285458",
                    "vmid": "fb:app_id"
                }, /*{
                    "property": "og:image",
                    "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.product.images[0] }`,
                    "vmid": "og:image"

                }*/]
            }
        },
        props: {
            "treatmentId": {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                extraBookingDiscounts: [{
                    value: "add-mono-sculpting-$800",
                    text: `加購 <a target="_blank" href="https://www.marierskincare.com/campaign/mono-sculpting">Mono Sculpting</a> 超頻消脂修身療程 (組合試做價 +$800)`,
                }],

                gSheetIds: {
                    "尖沙咀彌敦道132號美麗華廣場A座6樓602室": "AKfycby93s7mJnKmzHMVXiFV61PW7IbdNsPZeBjdRypSQE8cybctymo8" // TST
                },
            };
        },
        computed: {
            campaign () {
                let target = this.$store.state.mall.campaigns.find(
                    campaign => campaign.treatmentId === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {};
            },

            treatment () {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.treatmentId
                );
                if (target) {
                    return target;
                }
                return {
                    title: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    subtitle: {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    availableBranches: [],
                    images: [],
                    cover: "",
                };
            },
        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
    @font-face {
        font-family: "Athene";
        src: url("../../assets/campaign/emsculpt/athene.otf") format("opentype");
    }

    .font-athene {
        font-family: "Athene", serif;
    }

    @import url("https://fonts.googleapis.com/css?family=Noto+Serif+TC:900&display=swap");

    .font-noto-serif-tc {
        font-family: "Noto Serif TC", serif;
    }
</style>

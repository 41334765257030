<template>
    <div class="position-fixed btn-register-now-wrapper"
         data-aos="fade"
         data-aos-duration="1000"
         data-aos-delay="250">
        <div class="position-absolute pear">
            <img class="w-100 h-auto"
                 alt="Strong Pear"
                 src="../../../assets/campaign/emsculpt/home_pear2.png">
            <img class="face position-absolute"
                 alt="Strong Pear Face"
                 src="../../../assets/campaign/emsculpt/home_pear_face2.png">
        </div>
        <button class="position-relative d-none d-xl-block btn btn-lg btn-outline-dark rounded-0 btn-register-now"
                v-on:click="scrollToForm">
            立即登記
        </button>
        <button class="position-relative d-block d-xl-none btn btn-outline-dark rounded-0 btn-register-now"
                v-on:click="scrollToForm">
            立即登記
        </button>
    </div>
</template>

<script>
    export default {
        name: "BtnRegisterNow",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {
            scrollToForm () {
                document.getElementById("enquiry").scrollIntoView({
                    "behavior": "smooth"
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .btn-register-now-wrapper {
        bottom: 15px;
        left: 15px;
        z-index: 9;

        .btn-register-now {
            background-color: rgba(255, 255, 255, 1);
            z-index: 2;

            &:focus,
            &:hover {
                color: rgba(255, 255, 255, 1);
                background-color: rgba(113, 113, 113, 1);
            }
        }

        .pear {
            top: -200%;
            left: 10%;

            width: 80%;

            .face {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
